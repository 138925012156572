<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from 'axios';

export default {
    data: function () {
		return {
			character: []
		}
	},
	created: async function () {
		await this.fetchCharacterData()
	},
	methods: {
		fetchCharacterData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/character/' + this.$route.params.charid
            this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.character = response.data
                document.title = response.data.name + ' | XIVDATA';
				this.debugLevel(['Character data successfully loaded: ', this.character])
			}).catch((error) => {
				this.debugLevel('Character data failed to load with error: ' + error)
			})
		},

        debugLevel: function (value, level = 2) {
            const values = [];
            Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
            switch (level) {
                case 1:
                if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                case 2:
                if (process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                  }
                break;
                default:
                    values.forEach(element => console.log(element));
                break;
            }
        },
	},
  page: {
    title: "Portrait",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
};
</script>
<template>
    <Layout>
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="page-title-box">
                    <h4 class="page-title">{{ character.name }}</h4>
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">XIVDATA</li>
                        <li class="breadcrumb-item">{{ $t('messages.character') }}</li>
                        <li class="breadcrumb-item active">{{ character.name }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-3">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title font-16 mt-0"><img class="img-fluid rounded rounded-circle" :src="character.avatar"> {{ character.name }} <i v-if="character.gender == 2" class="far fa-venus"></i><i v-if="character.gender == 1" class="far fa-mars"></i></h4>
                                    </div>
                                    <div class="nav flex-column nav-pills">
                                        <router-link :to="{path: '/character/' + character.charid}" class="nav-link"><i class="fas fa-home"></i> {{ $t('character.info_button') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/portrait'}" class="nav-link"><i class="far fa-user"></i> {{ $t('character.portrait') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/classjobs'}" class="nav-link"><i class="far fa-envelope"></i> {{ $t('character.classjobs') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/mounts'}" class="nav-link"><i class="fas fa-horse"></i> {{ $t('character.mounts') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/minions'}" class="nav-link"><i class="fas fa-cat"></i> {{ $t('character.minions') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/archievments'}" class="nav-link"><i class="fas fa-trophy"></i> {{ $t('character.archievments') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/screenshots'}" class="nav-link"><i class="fas fa-images"></i> {{ $t('character.screenshots') }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-xl-9">
                                <img :src="character.portrait" class="img-fluid rounded mx-auto d-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>